$(document).ready(function () { 
	$('.slider-list').owlCarousel({
		loop:true,
		margin:15,
		responsiveClass:true,
		autoplay: true,
		nav:false,
		autoplayTimeout: 6000,
		autoplaySpeed: 5000,
		touchDrag: false,
		mouseDrag: false,
		autoplayHoverPause: true,
		responsive:{
			0:{
				items:3,
				nav:false
			},
			600:{
				items:3,
				nav:false
			},
			1000:{
				items:6,
				nav:false
			},
			1600:{
				items:6,
				nav:false
			}
		}
	});
})